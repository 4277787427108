.filter, .style_inventory_container, .recommended_action_container, .review_recommendation_container{
    background-color: #fff;
    padding: 1rem 1rem 4.5rem;
    margin: 1rem;
}

.store2store_container{
    position: relative;
    padding: 24px 18px 24px 10px;
    width: 100%;
    max-width: 100%;
}